/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DeliveryProviderOption {
    DPD = 'DPD',
    EVERMILE = 'Evermile',
    ONFLEET = 'Onfleet',
    RMTRACKED24 = 'RMTracked24',
    RMTRACKED48 = 'RMTracked48',
}
