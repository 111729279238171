/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Address } from '../models/Address';
import type { AdminOrderUpdate } from '../models/AdminOrderUpdate';
import type { AdminServiceUpdate } from '../models/AdminServiceUpdate';
import type { AreaModel } from '../models/AreaModel';
import type { BaseBasketItem } from '../models/BaseBasketItem';
import type { BaseShift } from '../models/BaseShift';
import type { Body_bulk_push_admin_notifications_bulk_push_post } from '../models/Body_bulk_push_admin_notifications_bulk_push_post';
import type { Body_update_service_details_admin_update_service_details_post } from '../models/Body_update_service_details_admin_update_service_details_post';
import type { DeliveryProviderOption } from '../models/DeliveryProviderOption';
import type { FeeRequest } from '../models/FeeRequest';
import type { Item } from '../models/Item';
import type { NewItem } from '../models/NewItem';
import type { Order } from '../models/Order';
import type { OrderWithCustomer } from '../models/OrderWithCustomer';
import type { Priority } from '../models/Priority';
import type { Question } from '../models/Question';
import type { ResolvedQuestion } from '../models/ResolvedQuestion';
import type { ServiceEvent } from '../models/ServiceEvent';
import type { ServiceInBasket } from '../models/ServiceInBasket';
import type { ServiceStatus } from '../models/ServiceStatus';
import type { Shift } from '../models/Shift';
import type { ShiftOverview } from '../models/ShiftOverview';
import type { ShiftWithOrders } from '../models/ShiftWithOrders';
import type { ShopifyStoreWithAccessToken } from '../models/ShopifyStoreWithAccessToken';
import type { Store } from '../models/Store';
import type { StoreImageUpdate } from '../models/StoreImageUpdate';
import type { StoreItemCustomisations } from '../models/StoreItemCustomisations';
import type { SuccessResponse } from '../models/SuccessResponse';
import type { Tailor } from '../models/Tailor';
import type { TailorItemDetails } from '../models/TailorItemDetails';
import type { Tier } from '../models/Tier';
import type { UserModel } from '../models/UserModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminService {

    /**
     * Put Register Admin
     * Update an existing customer
     * @param customerIdToUpdate 
     * @returns UserModel Updated customer
     * @throws ApiError
     */
    public static putRegisterAdminAdminRegisterPut(
customerIdToUpdate: string,
): CancelablePromise<UserModel> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/admin/register',
            query: {
                'customer_id_to_update': customerIdToUpdate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Fee
     * Insert a new fee
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static postFeeAdminFeesPost(
requestBody: FeeRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/fees',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Items
     * Insert a new item with its own alterations/repairs
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static postItemsAdminItemsPost(
requestBody: Array<NewItem>,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/items',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * New Shift
     * Add a new shift
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static newShiftAdminNewShiftPost(
requestBody: BaseShift,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/new_shift',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * All Shifts
     * Get all shifts
     * @returns ShiftOverview List of all shifts and the number of orders they have
     * @throws ApiError
     */
    public static allShiftsAdminAllShiftsGet(): CancelablePromise<Array<ShiftOverview>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/all_shifts',
        });
    }

    /**
     * Get Single Shift
     * @param shiftId 
     * @returns ShiftWithOrders Shift overview with all order info for that shift attached
     * @throws ApiError
     */
    public static getSingleShiftAdminSingleShiftGet(
shiftId: string,
): CancelablePromise<ShiftWithOrders> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/single_shift',
            query: {
                'shift_id': shiftId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Single Shift
     * @param requestBody 
     * @returns Shift The updated portion of the shift
     * @throws ApiError
     */
    public static putSingleShiftAdminSingleShiftPut(
requestBody: ShiftWithOrders,
): CancelablePromise<Shift> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/admin/single_shift',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Questions
     * Add a new question for a specified item
     * @param itemId 
     * @param requestBody 
     * @returns ResolvedQuestion Updated list of questions for the given item
     * @throws ApiError
     */
    public static postQuestionsAdminQuestionsPost(
itemId: string,
requestBody: Array<Question>,
): CancelablePromise<Array<ResolvedQuestion>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/questions',
            query: {
                'item_id': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Area
     * Insert a new area
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static postAreaAdminAreaPost(
requestBody: AreaModel,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/area',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Shopify Store
     * Get a shopify store's details
     * @param id 
     * @returns ShopifyStoreWithAccessToken Shopify store details
     * @throws ApiError
     */
    public static shopifyStoreAdminShopifyStoreGet(
id: string,
): CancelablePromise<ShopifyStoreWithAccessToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/shopify_store',
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Shopify Store
     * Update a shopify store
     * @param requestBody 
     * @returns SuccessResponse Update a shopify store's details
     * @throws ApiError
     */
    public static putShopifyStoreAdminShopifyStorePut(
requestBody: ShopifyStoreWithAccessToken,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/admin/shopify_store',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * All Shopify Stores
     * Get a shopify store's details
     * @returns ShopifyStoreWithAccessToken Shopify store details
     * @throws ApiError
     */
    public static allShopifyStoresAdminAllShopifyStoresGet(): CancelablePromise<Array<ShopifyStoreWithAccessToken>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/all_shopify_stores',
        });
    }

    /**
     * Store
     * Get a store's details
     * @param id 
     * @returns Store Store details
     * @throws ApiError
     */
    public static storeAdminStoreStoreGet(
id: string,
): CancelablePromise<Store> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/store/store',
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Store
     * Update a store
     * @param requestBody 
     * @returns SuccessResponse Update a store's details
     * @throws ApiError
     */
    public static putStoreAdminStoreStorePut(
requestBody: Store,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/admin/store/store',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Store Basic
     * Get a store's details
     * @param id 
     * @returns Store Store details
     * @throws ApiError
     */
    public static storeBasicAdminStoreStoreBasicGet(
id: string,
): CancelablePromise<Store> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/store/store_basic',
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Store Images
     * Update a store's images
     * @param requestBody 
     * @returns SuccessResponse Update a store's images
     * @throws ApiError
     */
    public static putStoreImagesAdminStoreStoreImagesPut(
requestBody: StoreImageUpdate,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/admin/store/store_images',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * All Stores
     * Get a store's details
     * @returns Store Store details
     * @throws ApiError
     */
    public static allStoresAdminStoreAllStoresGet(): CancelablePromise<Array<Store>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/store/all_stores',
        });
    }

    /**
     * All Stores Basic
     * Get a store's details
     * @returns Store Store details
     * @throws ApiError
     */
    public static allStoresBasicAdminStoreAllStoresBasicGet(): CancelablePromise<Array<Store>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/store/all_stores_basic',
        });
    }

    /**
     * Post Store Item Customisations
     * @param sojoDomain 
     * @param requestBody 
     * @returns StoreItemCustomisations Update item services being offered on the store
     * @throws ApiError
     */
    public static postStoreItemCustomisationsAdminStoreStoreItemCustomisationsPost(
sojoDomain: string,
requestBody: Array<StoreItemCustomisations>,
): CancelablePromise<Array<StoreItemCustomisations>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/store/store_item_customisations',
            query: {
                'sojo_domain': sojoDomain,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add All Store Item Customisations
     * @param sojoDomain 
     * @returns SuccessResponse Add all item customisations to the store
     * @throws ApiError
     */
    public static addAllStoreItemCustomisationsAdminStoreAddAllStoreItemCustomisationsPost(
sojoDomain: string,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/store/add_all_store_item_customisations',
            query: {
                'sojo_domain': sojoDomain,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Enable Webhook
     * @param storeId 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static enableWebhookAdminStoreEnableWebhookPost(
storeId: string,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/store/enable_webhook',
            query: {
                'store_id': storeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Push
     * @param requestBody 
     * @returns SuccessResponse Bulk push notifications to email list
     * @throws ApiError
     */
    public static bulkPushAdminNotificationsBulkPushPost(
requestBody: Body_bulk_push_admin_notifications_bulk_push_post,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/notifications/bulk_push',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Send Abandoned Basket Email
     * @param minMinutesAgo 
     * @param maxMinutesAgo 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static getSendAbandonedBasketEmailAdminSendAbandonedBasketEmailGet(
minMinutesAgo: number,
maxMinutesAgo: number,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/send_abandoned_basket_email',
            query: {
                'min_minutes_ago': minMinutesAgo,
                'max_minutes_ago': maxMinutesAgo,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Order Statuses
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static updateOrderStatusesAdminShopifyUpdateOrderStatusesPost(): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/shopify/update_order_statuses',
        });
    }

    /**
     * Storefront Orders
     * @param storefrontId 
     * @returns Order List of orders placed at a specified storefront
     * @throws ApiError
     */
    public static storefrontOrdersAdminStorefrontOrdersGet(
storefrontId: string,
): CancelablePromise<Array<Order>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/storefront_orders',
            query: {
                'storefront_id': storefrontId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Admin Order
     * @param requestBody 
     * @returns Order The updated order
     * @throws ApiError
     */
    public static updateAdminOrderAdminUpdateAdminOrderPost(
requestBody: AdminOrderUpdate,
): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/update_admin_order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Admin Items
     * @param requestBody 
     * @returns Order The full order containing the updated services
     * @throws ApiError
     */
    public static updateAdminItemsAdminUpdateAdminServicePost(
requestBody: AdminServiceUpdate,
): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/update_admin_service',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Send Pickup Reminder
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static sendPickupReminderAdminSendPickupReminderGet(): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/send_pickup_reminder',
        });
    }

    /**
     * Send Dropoff Reminder
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static sendDropoffReminderAdminSendDropoffReminderGet(): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/send_dropoff_reminder',
        });
    }

    /**
     * Orders By Collection Ref
     * @param collectionRef 
     * @returns OrderWithCustomer Orders with matching collection reference
     * @throws ApiError
     */
    public static ordersByCollectionRefAdminOrdersByCollectionRefGet(
collectionRef: string,
): CancelablePromise<Array<OrderWithCustomer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/orders_by_collection_ref',
            query: {
                'collection_ref': collectionRef,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Empty Item
     * @param orderId 
     * @param requestBody 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static addEmptyItemAdminAddEmptyItemPost(
orderId: string,
requestBody: BaseBasketItem,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/add_empty_item',
            query: {
                'order_id': orderId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Service
     * @param orderId 
     * @param uniqueItemId 
     * @param servicePrice 
     * @param requestBody 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static addServiceAdminAddServicePost(
orderId: string,
uniqueItemId: string,
servicePrice: number,
requestBody: ServiceInBasket,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/add_service',
            query: {
                'order_id': orderId,
                'unique_item_id': uniqueItemId,
                'service_price': servicePrice,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Order Details
     * @param orderId 
     * @param email 
     * @param phone 
     * @param totalPrice 
     * @param neighbour 
     * @param priority 
     * @param requestBody 
     * @param deliveryInstructions 
     * @param safePlace 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static updateOrderDetailsAdminUpdateOrderDetailsPost(
orderId: string,
email: string,
phone: string,
totalPrice: number,
neighbour: boolean,
priority: Priority,
requestBody: Address,
deliveryInstructions?: string,
safePlace?: string,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/update_order_details',
            query: {
                'order_id': orderId,
                'email': email,
                'phone': phone,
                'total_price': totalPrice,
                'neighbour': neighbour,
                'priority': priority,
                'delivery_instructions': deliveryInstructions,
                'safe_place': safePlace,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Item Details
     * @param orderId 
     * @param uniqueItemId 
     * @param itemDescription 
     * @param pricingTier 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static updateItemDetailsAdminUpdateItemDetailsPost(
orderId: string,
uniqueItemId: string,
itemDescription: string,
pricingTier: Tier,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/update_item_details',
            query: {
                'order_id': orderId,
                'unique_item_id': uniqueItemId,
                'item_description': itemDescription,
                'pricing_tier': pricingTier,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Service Details
     * @param orderId 
     * @param uniqueItemId 
     * @param uniqueServiceId 
     * @param price 
     * @param requestBody 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static updateServiceDetailsAdminUpdateServiceDetailsPost(
orderId: string,
uniqueItemId: string,
uniqueServiceId: string,
price: number,
requestBody?: Body_update_service_details_admin_update_service_details_post,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/update_service_details',
            query: {
                'order_id': orderId,
                'unique_item_id': uniqueItemId,
                'unique_service_id': uniqueServiceId,
                'price': price,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Status Check
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static statusCheckAdminStatusCheckPost(): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/status_check',
        });
    }

    /**
     * Book Royal Mail Delivery
     * @param orderId 
     * @param deliveryProvider 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static bookRoyalMailDeliveryAdminBookRoyalMailDeliveryPost(
orderId: string,
deliveryProvider: DeliveryProviderOption,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/book_royal_mail_delivery',
            query: {
                'order_id': orderId,
                'delivery_provider': deliveryProvider,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Book Onfleet Delivery
     * @param orderId 
     * @param dropoffDate 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static bookOnfleetDeliveryAdminBookOnfleetDeliveryPost(
orderId: string,
dropoffDate: string,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/book_onfleet_delivery',
            query: {
                'order_id': orderId,
                'dropoff_date': dropoffDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login
     * @param accessCode 
     * @returns Tailor The tailor
     * @throws ApiError
     */
    public static loginAdminTailorLoginPost(
accessCode: string,
): CancelablePromise<Tailor> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/tailor/login',
            query: {
                'access_code': accessCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Assigned Items
     * @param tailorId 
     * @returns TailorItemDetails Items with details and services assigned to tailor
     * @throws ApiError
     */
    public static assignedItemsAdminTailorAssignedItemsGet(
tailorId: string,
): CancelablePromise<Array<TailorItemDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/tailor/assigned_items',
            query: {
                'tailor_id': tailorId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Qc Ready Items
     * @returns TailorItemDetails Items which are QC ready
     * @throws ApiError
     */
    public static qcReadyItemsAdminTailorQcReadyItemsGet(): CancelablePromise<Array<TailorItemDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/tailor/qc_ready_items',
        });
    }

    /**
     * Incoming Items
     * @param collectionRef 
     * @returns TailorItemDetails Incoming items based on collection reference
     * @throws ApiError
     */
    public static incomingItemsAdminTailorIncomingItemsGet(
collectionRef: string,
): CancelablePromise<Array<TailorItemDetails>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/tailor/incoming_items',
            query: {
                'collection_ref': collectionRef,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Service Status
     * @param serviceId 
     * @param serviceStatus 
     * @param serviceEvent 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static updateServiceStatusAdminTailorUpdateServiceStatusPost(
serviceId: string,
serviceStatus: ServiceStatus,
serviceEvent: ServiceEvent,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/tailor/update_service_status',
            query: {
                'service_id': serviceId,
                'service_status': serviceStatus,
                'service_event': serviceEvent,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Tailor Points
     * @param serviceId 
     * @param tailorPoints 
     * @returns SuccessResponse Successful Response
     * @throws ApiError
     */
    public static updateTailorPointsAdminTailorUpdateTailorPointsPost(
serviceId: string,
tailorPoints: number,
): CancelablePromise<SuccessResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/tailor/update_tailor_points',
            query: {
                'service_id': serviceId,
                'tailor_points': tailorPoints,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * All Items
     * @returns Item List of all items
     * @throws ApiError
     */
    public static allItemsAdminAllItemsGet(): CancelablePromise<Array<Item>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/all_items',
        });
    }

}
