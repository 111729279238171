/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Static strings defining user types
 */
export enum UserType {
    CUSTOMER = 'customer',
    SEAMSTER = 'seamster',
    RIDER = 'rider',
    SHOP = 'shop',
}
